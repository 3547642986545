<template>
  <div class="screen-admin-page screen-admin-usaga-report">
    <div>
      <div class="toolbar">
        <div class="title">
          <h1>Usage Report {{filteredMonth.format('MMM YYYY')}}</h1>
        </div>
        <div class="tools">
          <button
            @click="updateMonth(prevMonth)"
            v-if="prevMonth"
            class="btn"
          >{{prevMonth.format('MMM YYYY')}}</button>
          <button
            class="btn"
            disabled
          >{{filteredMonth.format('MMM YYYY')}}</button>
          <button
            @click="updateMonth(nextMonth)"
            v-if="nextMonth"
            class="btn"
          >{{nextMonth.format('MMM YYYY')}}</button>
        </div>
      </div>
      <rz-request-state :state="state" />
      <div
        class="usage-report admin-screen-content"
        v-if="state.status.isLoaded"
      >
        <div class="item-list-row item-list">
          <div class="item-list-col item-report-summary invoice-report-items">
            <table class="no-title">
              <thead>
                <tr>
                  <th></th>
                  <th>Projects</th>
                  <th>Sales</th>
                  <th>Sales EUR</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in reportItems"
                  :key="item.key"
                  :class="[item.key]"
                >
                  <td>{{item.name}}</td>
                  <td>{{item.report.projects}}</td>
                  <td>{{formatCurrency(item.report.amount, item.currency, true)}}</td>
                  <td>{{formatCurrency(item.report.eur, 'eur', true)}}</td>
                </tr>
              </tbody>
            </table>
            <p>This report doesn't include Enterprice Cost and GST</p>
          </div>
        </div>
        <hr />
        <div class="download-btn-wrapper">
          <button class="download-btn" @click="downloadUsages()">
            Download Usages
          </button>
        </div>
        <FilterableView
          :filterables="filterables"
          @item-toggle="onFilterItemToggle"
        >
          <div class="item-list-row item-list">
            <div class="item-list-col item-report-summary">
              <table class="no-row-border no-title">
                <thead>
                  <tr>
                    <th>Updated</th>
                    <th>Name</th>
                    <th>Project Key</th>
                    <th>Hits</th>
                    <th>Resources</th>
                    <th>Cost</th>
                    <th>Project created</th>
                    <th>€</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in report.items"
                    :key="item.project.key"
                  >
                    <td>
                      <router-link
                        :to="{ name: 'usageReport', params: { projectKey: item.project.key },
                        query: { month: filteredMonth.format('MM') , year: item.year} }"
                      >
                        {{ $filters.utcLocalDate(item.updated) }}
                      </router-link>
                    </td>
                    <td>{{item.project_raw.name || '-'}}</td>
                    <td class="add-pointer" @click="showPreviewProject(item)">
                      {{item.project.key}}
                    </td>
                    <td>{{formatNumber(item.api_hits)}}</td>
                    <td>{{formatNumber(item.resources_count)}}</td>
                    <td>
                      <router-link
                        :to="{ name: 'usageReport', params: { projectKey: item.project.key },
                        query: { month: filteredMonth.format('MM') , year: item.year} }"
                      >
                        {{formatCurrency(item.cost, item.currency, true)}}
                      </router-link>
                    </td>
                    <td>{{item.project_raw.created ? $filters.formatDate(item.project_raw.created) : '-'}}</td>
                    <td>
                      {{formatCurrency(item.eur, 'eur', true)}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </FilterableView>
      </div>
       <QuickPreviewView
        v-if="previewProject && previewProject.project"
      >
        <template v-slot:left>
          <ul class="item-box">
            <li
              v-for="report in report.items"
              :key="report.key"
              @click="showPreviewProject(report)"
              :class="{active: report.project.key === previewProject.project.key}"
            >
            {{report.project.key}}
            </li>
          </ul>
        </template>
        <template v-slot:title>
          <h1>{{previewProject.project.key}}</h1>
          <div class="tools">
            <router-link
              class="btn"
              :to="{name: 'ProjectPage', params: {projectKey: previewProject.project.key}}"
            >Open</router-link>
            <button
              @click="hidePreviewProject"
              class="btn"
            >X</button>
          </div>
        </template>
        <template v-slot:content>
          <ProjectPreview
            :projectKey="previewProject.project.key"
            @update="onPreviewUpdate"
          />
        </template>
      </QuickPreviewView>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import Config from '@/config';
import { ref, computed, onMounted } from 'vue';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  FilterableItem,
  Filterables,
  UsageReport,
} from '@/store/modules/admin';
import { useStore } from 'vuex';
import FilterableView from '@/components/admin/Filterable.vue';
import { currencyMethodsMixin } from '@/mixin/currency';
import ProjectPreview from '@/components/admin/ProjectPreview.vue';
import QuickPreviewView from '@/components/admin/QuickPreviewView.vue';

export default {
  mixins: [currencyMethodsMixin],
  components: {
    FilterableView,
    ProjectPreview,
    QuickPreviewView,
  },

  setup() {
    const store = useStore();
    const state = ref(new RequestState());
    const report = ref([]);
    const previewProject = ref({});
    const filteredMonth = ref(moment());
    const prevMonth = ref(null);
    const nextMonth = ref(null);
    const actualItems = ref([]);
    const showBilledItem = ref(
      new FilterableItem({
        name: 'Show Billed Items',
        isCheckbox: true,
        number: 0,
        selected: true,
      }),
    );
    const showUnbilledItem = ref(
      new FilterableItem({
        name: 'Show Unbilled Items',
        isCheckbox: true,
        number: 0,
        selected: false,
      }),
    );
    const filterables = ref(new Filterables({
      items: [
        showBilledItem.value,
        showUnbilledItem.value,
      ],
    }));

    const showPreviewProject = (project) => {
      previewProject.value = project;
    };

    const hidePreviewProject = () => {
      previewProject.value = {};
      store.commit('admin/updatePreviewProject', { project: null });
      return false;
    };

    const downloadUsages = () => {
      const year = filteredMonth.value.year();
      const month = filteredMonth.value.format('MM');
      const appkey = Config.rzAppKey;
      const apikey = Config.rzApiKey;
      let url = null;
      // eslint-disable-next-line max-len
      url = `/backend/rest/admin/business/usages/${year}/${month}/?rz-api-key=${apikey}&rz-app-key=${appkey}&as_csv=1`;
      window.open(url, '_blank');
    };

    const updateFilterItems = () => {
      const allItems = [...actualItems.value];
      let filteredItems = [...allItems];

      showBilledItem.value.number = allItems.filter((x) => x.eur > 0).length;
      showUnbilledItem.value.number = allItems.filter((x) => x.eur <= 0).length;

      if (showBilledItem.value.selected) {
        filteredItems = filteredItems.filter((x) => x.eur > 0);
      }
      if (showUnbilledItem.value.selected) {
        filteredItems = filteredItems.filter((x) => x.eur <= 0);
      }

      report.value.items = filteredItems;
      report.value.buildReport();
    };

    const fetchData = () => {
      state.value.status = RequestStateStatus.Loading;
      UsageReport.fetch(filteredMonth.value).then((_report) => {
        report.value = _report;
        actualItems.value = [..._report.items];
        state.value.status = RequestStateStatus.Loaded;
        updateFilterItems();
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    const updateMonths = () => {
      prevMonth.value = filteredMonth.value.clone().add(-1, 'months');
      if (moment().isSame(filteredMonth.value, 'month')) {
        nextMonth.value = null;
      } else {
        nextMonth.value = filteredMonth.value.clone().add(1, 'months');
      }
    };

    const updateMonth = (month) => {
      filteredMonth.value = month;
      updateMonths();
      fetchData();
    };

    const reportItems = computed(() => {
      const items = [
        {
          key: 'eur', name: 'EUR', currency: 'EUR', report: report.value.totalByCurrency.eur,
        },
        {
          key: 'usd', name: 'USD', currency: 'USD', report: report.value.totalByCurrency.usd,
        },
        {
          key: 'inr', name: 'INR', currency: 'INR', report: report.value.totalByCurrency.inr,
        },
        {
          key: 'total', name: 'Total', currency: 'EUR', report: report.value.total,
        },
      ];
      return items;
    });

    const onFilterItemToggle = () => {
      updateFilterItems();
    };

    onMounted(() => {
      updateMonths();
      fetchData();
    });

    return {
      state,
      report,
      updateMonth,
      filteredMonth,
      prevMonth,
      nextMonth,
      reportItems,
      filterables,
      onFilterItemToggle,
      showPreviewProject,
      hidePreviewProject,
      previewProject,
      downloadUsages,
    };
  },
};
</script>
<style lang="scss" scoped>
.download-btn-wrapper {
  display: flex;
  justify-content: end;
  button {
    padding: size(10) size(8);
    border-radius: size(6);
    background: #5168e5;
    margin-top: size(30);
    font-size: size(14);
    cursor: pointer;
  }
}
</style>
